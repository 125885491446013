import { Text, Box, Flex, Icon, SimpleGrid, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { MdNavigateBefore, MdNavigateNext, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight, MdCheckCircle, MdCancel, MdOutlineError, MdPending, MdSearch,MdDoNotDisturbOn, MdCreate, MdDoNotDisturb} from "react-icons/md";
import ColumnsTable from "views/admin/decisionSummary/components/ColumnsTable";
import { React, useState, useEffect, useLayoutEffect } from "react";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { MdBarChart, MdTrendingUp, MdMultilineChart, MdOutlineTroubleshoot, MdOutlineQueryStats, MdCurrencyExchange, MdOutlineSpeed, MdStarHalf } from "react-icons/md";
import { BiSolidEdit } from "react-icons/bi";
import { eclColumnsDataRevenue, eclColumnsDataDebt, eclRiskColumn, eclLineColumn, eclKybColumn, eclBankColumn, eclDecisionColumn, eclRevenueAggregation, eclRevenueFromBank, eclRevenueFromFinancials, eclRevenueFromInvoices, eclDebtAggregation, eclDebtFromBank, eclDebtFromCodat, duplicationRulesColumns, valueThresholdRulesColumns } from "views/admin/decisionSummary/variables/eclColumnData";
import service from "utils/service";
import FixedHeaderTop from "components/fixedHeaderDecisionExtend/FixedHeaderTop";
import Period from "components/period/Period";
import { getCurrencyValue } from "utils/helperFunctions/getCurrencyValue";
import { apiendpoints } from "utils/apiendpoints";
import { mapSelectedDecisions } from "redux/actions/infoAction";
import { useDispatch, useSelector } from "react-redux";
import { localStorageKeys } from "utils/localStorageKeys";
import moment from "moment";
import { useHistory } from "react-router-dom";
import LazyLoader from "components/lazyLoader/LazyLoader";
import SomethingWrong from "components/errorStates/SomethingWrong";
import NoResults from "components/errorStates/NoResults";
import { withPercentWithDecimal, valueChecksWithoutDollarWithDecimal, valueChecksWithoutDollarWithoutDecimal } from "utils/helperFunctions/processValue";
import { typeOf } from "react-is";
import DisclaimerPage from "components/errorStates/DisclaimerPage";
import { useLocation } from 'react-router-dom';
import CustomRevenuePopup from "./components/customRevenuePopup";
import CustomDebtPopup from "./components/customDebtPopup";
import { ToastContainer, toast } from 'react-toastify';
import { CheckCircleIcon } from '@chakra-ui/icons'
// import FraudCheck from "./components/fraudCheck";
import { mapDuplicationResToTable, mapThresholdResToTable } from "./utils/fraudCheckFunc";

export default function DecisionSummary({
  match: {
    params: {
      _id,
    }
  },
  match,
  ...props
}) {

  const location = useLocation();

  const brandColor = useColorModeValue("cbrand.500", "white");

  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const { selectedDecisions, selectedApplications, activeAppsRedux } = useSelector(state => state.infoReducer)

  const dispatch = useDispatch();

  const history = useHistory()

  const [revenueTableData, setRevenueTableData] = useState([])

  const [content, setContent] = useState([])

  const [debtTableData, setDebtTableData] = useState([])

  const [revenueBankTableData, setRevenueBankTableData] = useState([])

  const [debtTablBankeData, setDebtBankTableData] = useState([])

  const [riskTable, setRiskTable] = useState([])

  const [lineTable, setLineTable] = useState([])

  const [kybTable, setKybTable] = useState([])

  const [summaryTable, setSummaryTable] = useState([])

  const [kpiData, setKpiData] = useState({})

  const [decisionId, setDecisionId] = useState("");

  const [activeAppId, setActiveAppId] = useState("");

  const [months, setMonths] = useState('')

  const [decisionRunDate, setDecisionRunDate] = useState('')

  const defaultResultsPerPage = 12

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPage, setTotalPage] = useState(null);

  const [resultsPerPage, setResultsPerPage] = useState(defaultResultsPerPage);

  const [totalRows, setTotalRows] = useState('');

  const [currentPageD, setCurrentPageD] = useState(1);

  const [totalPageD, setTotalPageD] = useState(null);

  const [resultsPerPageD, setResultsPerPageD] = useState(defaultResultsPerPage);

  const [totalRowsD, setTotalRowsD] = useState('');

  const [loader, setLoader] = useState(false)

  const [error, setError] = useState(false)

  const [revenueAggregation, setRevenueAggregation] = useState([])

  const [revenueFromBank, setRevenueFromBank] = useState([])

  const [RevenueFromFinancials, setRevenueFromFinancials] = useState([])

  const [RevenueFromInvoices, setRevenueFromInvoices] = useState([])

  const [debtAggregation, setDebtAggregation] = useState([])

  const [debtFromBank, setDebtFromBank] = useState([])

  const [debtFromCodat, setDebtFromCodat] = useState([])

  const [dataSourceText, setDataSourceText] = useState('')

  const [loanStatus, setLoanStatus] = useState(false)

  const [loanStatusText, setLoanStatusText] = useState('')

  const [disclaimerText, setDisclaimerText] = useState('')

  const [failedStatusText, setFailedStatusText] = useState('')

  const [height, setHeight] = useState(0);

  const [isRevenueModalOpen, setIsRevenueModalOpen] = useState(false);

  const [isDebtModalOpen, setDebtModalOpen] = useState(false);

  const [customRevenuePopupOptions, setCustomRevenuePopupOptions] = useState({})

  const [selectedCustomRevenuePopupOptions, setSelectedCustomRevenuePopupOptions] = useState({ label: 'Select', value: 'custom' })

  const [customRevenueEditedValue, setCustomRevenueEditedValue] = useState('')

  const [inputValue, setInputValue] = useState('');

  const [updatedValue, setUpdatedValue] = useState('');

  const [isCustom, setIsCustom] = useState('')

  const [customType, setCustomType] = useState('')

  const [minPercentage, setMinPercentage] = useState('')

  const [maxPercentage, setMaxPercentage] = useState('')

  const [selectedCustomRevenuePopupOptionsIndicator, setSelectedCustomRevenuePopupOptionsIndicator] = useState('');

  const [inputValueIndicator, setInputValueIndicator] = useState('')

  const [resDecisionId, setResDecisionId] = useState('')

  const [bankBasedPopupData, setBankBasedPopupData] = useState('')
  const [codatBasedPopupData, setCodatBasedPopupData] = useState('')

  const [bankBasedInputValue, setBankBasedInputValue] = useState('');
  const [codatBasedInputValue, setCodatBasedInputValue] = useState('');
  const [debtCustomInputValue, setDebtCustomInputValue] = useState('');

  const [bankBasedUpdatedValue, setBankBasedUpdatedValue] = useState('');
  const [codatBasedUpdatedValue, setCodatBasedUpdatedValue] = useState('');
  const [totalBasedUpdatedValue, setTotalBasedUpdatedValue] = useState('');

  const [fileDataArray, setFileDataArray] = useState([]);
  const [fileCommentArray, setFileCommentArray] = useState([]);
  const [fileDataArray1, setFileDataArray1] = useState([]);
  const [fileCommentArray1, setFileCommentArray1] = useState([]);

  const [comments, setComments] = useState([]);

  const [customDebtEditedValue, setCustomDebtEditedValue] = useState('')

  const [fileCommentLoader, setFileCommentLoader] = useState(false)

  const [customData, setCustomData] = useState({});
  const [commentIsRequired, setCommentIsRequired] = useState(false);
  const [isEditing, setIsEditing] = useState({});

  const [showRunDecisionBtn, setShowRunDecisionBtn] = useState(false);

  const [customRevenueDetails, setCustomRevenueDetails] = useState('')



  const [duplicationRules, setDuplicationRules] = useState([])
  const [thresholdRules, setThresholdRules] = useState([])

  const revenueResultsPerPage = content?.monthly_revenue_estimation_count;

  console.log(revenueResultsPerPage, "revenueResultsPerPagerevenueResultsPerPage")

  const debtResultsPerPage = content?.monthly_debt_estimation_count;

  console.log(debtResultsPerPage, "debtResultsPerPagedebtResultsPerPage")

  const handleCustomRevenuePopupOption = (option) => {
    setSelectedCustomRevenuePopupOptions(option)
    if (inputValue !== "") {
      document.getElementById('tick-icon').style.color = '#00A2AD';
      document.getElementById('tick-icon').style.cursor = 'pointer';
      document.getElementById('tick-icon').style.pointerEvents = 'auto';
    }

  }

  const handleRevenueModal = () => {
    setIsRevenueModalOpen(true);
  };
  const handleDebtModal = () => {
    setDebtModalOpen(true);

    if (customData.bankBasedInputValue !== null && customData.bankBasedInputValue !== undefined) {
      setBankBasedInputValue(customData.bankBasedInputValue);
    }

    if (customData.codatBasedInputValue !== null && customData.codatBasedInputValue !== undefined) {
      setCodatBasedInputValue(customData.codatBasedInputValue);
    }

    if (customData.bankBasedUpdatedValue !== undefined) {
      setBankBasedUpdatedValue(customData.bankBasedUpdatedValue);
    }

    if (customData.codatBasedUpdatedValue !== null && customData.codatBasedUpdatedValue !== undefined) {
      setCodatBasedUpdatedValue(customData.codatBasedUpdatedValue);
    }

    if (customData.debtCustomInputValue !== null && customData.debtCustomInputValue !== undefined) {
      setDebtCustomInputValue(customData.debtCustomInputValue);
    }

    if (customData.totalBasedUpdatedValue !== null && customData.totalBasedUpdatedValue !== undefined) {
      setTotalBasedUpdatedValue(customData.totalBasedUpdatedValue);
    }

    if (fileDataArray1.length === 0 && fileCommentArray1.length === 0) {
      fetchCustomDebtProofs();
    }

  };


  const handleCloseModal = () => {
    setIsRevenueModalOpen(false);
    setDebtModalOpen(false);
  };



  function processValue(value) {
    switch (true) {
      case value === 0:
        return '$ 0';
      case value === null || isNaN(value):
        return '-';
      default:
        const roundedValue = Math.round(value);
        return Math.abs(roundedValue) < 1e-10 ? '$ 0' : getCurrencyValue(roundedValue);
    }
  }

  const fetchFinancialRangeData = () => {
    service.get(`${apiendpoints?.FINANCIAL_RANGE}`).then((res) => {
      const { content } = res
      setMinPercentage(content?.revenue_min_per)
      setMaxPercentage(content?.revenue_max_per)

    })
      .catch((error) => {
        console.error("financial range api not working", error);
      });

  }

  const fetchData = () => {
    setLoader(true)
    setLoanStatus(false)
    service.get(`${apiendpoints?.DECISIONS_SUMMARY}/${decisionId}/summary`).then((res) => {
      const { content } = res
      setLoader(false)
      setError(false)
      setContent(content)
      setFailedStatusText(content?.failed_reason)

      const customDecision = content?.custom_decision;

      if (customDecision?.length > 0) {

        setCustomData({
          bankBasedInputValue: customDecision[0]?.custom_bank_based_perc,
          codatBasedInputValue: customDecision[0]?.custom_codat_based_perc,
          bankBasedUpdatedValue: customDecision[0]?.custom_bank_based_estimate,
          codatBasedUpdatedValue: customDecision[0]?.custom_codat_based_estimate,
          debtCustomInputValue: customDecision[0]?.custom_value,
          totalBasedUpdatedValue: customDecision[0]?.custom_debt_estimate,
        })
      }

      if (content?.loan_status?.length > 0 && !["Declined", "Approved", "Manual Review"].includes(content?.loan_status)) {
        setLoanStatus(true)
        setLoanStatusText(content?.loan_status)
      } else {
        setLoanStatus(false)
      }

      setIsCustom(content?.is_custom)

      setDisclaimerText(content?.disclaimer_text)

      setCustomRevenueEditedValue(content?.custom_decision?.length > 0 && content?.is_custom ? (content?.custom_decision?.length > 0 ? content?.custom_decision[0]?.custom_revenue_estimate : '-') : '-');

      setCustomDebtEditedValue(content?.custom_decision?.length > 0 && content?.is_custom ? (content?.custom_decision?.length > 0 ? content?.custom_decision[0]?.custom_debt_estimate : '-') : '-');

      setBankBasedPopupData(content?.bank_based_estimated_debt)
      setCodatBasedPopupData(content?.final_codat_financial_debt_estimate)


      let rows = content?.monthly_revenue_estimation?.length;
      setTotalRows(rows);
      setTotalPage(Math.ceil(rows / resultsPerPage));

      let rowsD = content?.monthly_debt_estimation?.length;
      setTotalRowsD(rowsD);
      setTotalPageD(Math.ceil(rowsD / resultsPerPageD));

      let periodMonths = content?.period_of_study_months
      setMonths(periodMonths)

      let decRunDate = content?.created_at == null ? "-" : content?.created_at
      setDecisionRunDate(moment.utc(decRunDate).format("DD MMM YYYY,  h:mm A"))

      let dataSource = content?.data_sources_used == null ? "-" : content?.data_sources_used
      setDataSourceText(dataSource)

      let revenueTable = []
      revenueTable = content?.monthly_revenue_estimation.map((ele) => {
        return {
          "account": ele.account_number,
          "id": ele.id,
          "year": ele?.year,
          "month": ele?.month,
          // "credits": getCurrencyValue(Math.round((ele?.credits))),
          "credits": processValue(ele?.credits),
          "debits": processValue(ele?.debits),
          "sales": processValue(ele?.sales),
          "expenses": processValue(ele?.expenses),
          "burn": processValue(ele?.burn),
        }


      })
      setRevenueTableData(revenueTable.flat())

      let revenueAggregationTable = [
        {
          nameC: "Revenue from Application",
          valueC: processValue(content?.declared_revenue)
        },
        {
          nameC: "Latest Financial Revenue Estimate with Growth",
          valueC: processValue(content?.latest_financial_rev_esti_with_growth)
        },
        {
          nameC: "Latest Invoice Revenue Estimate with Growth",
          valueC: processValue(content?.latest_invoice_rev_estimate_with_growth)
        },
        {
          nameC: "Final Bank based revenue estimate",
          valueC: processValue(content?.bank_based_estimated_revenue)
        },
        {
          nameC: "Aggregated Selected Revenue Estimate",
          valueC: processValue(content?.revenue_estimation)
        },
        {
          nameC: "Custom Revenue Estimate",
          valueC:
            (<Flex justify={"space-between"} gap={"10px"} alignItems={"center"}>
              {processValue(customRevenueEditedValue)}<Icon onClick={() => handleRevenueModal()} cursor={"pointer"} as={BiSolidEdit} width="20px" height="20px" color="rgba(0, 162, 173, 1)" me={'5px'} /></Flex>
            )
        },
      ]
      setRevenueAggregation(revenueAggregationTable)


      let revenueFromBankTable = [
        {
          name: "Growth from Bank (QoQ)",
          value: withPercentWithDecimal(content?.qoq_growth) || '-'
        },
        {
          name: "Latest 365 days estimate with growth",
          value: processValue(content?.latest_365_days_growth_estimate)
        },
        {
          name: "Latest 180 days annualized estimate with growth",
          value: processValue(content?.latest_180_days_growth_estimate)
        },
        {
          name: "Latest 12 calendar months estimate",
          value: processValue(content?.latest_12_month_estimate)
        },
        {
          name: "Latest 12 calendar months estimate with growth",
          value: processValue(content?.latest_12_month_growth)
        },
        {
          name: "Latest 6 calendar months annualized estimate",
          value: processValue(content?.latest_6_month_estimate)
        },
        {
          name: "Latest 6 calendar months annualized estimate with growth",
          value: processValue(content?.latest_6_month_growth_estimate)
        },
        // {
        //   name: "Final Bank based revenue estimate",
        //   value: processValue(content?.bank_based_estimated_revenue)
        // },
      ]
      setRevenueFromBank(revenueFromBankTable)


      let revenueFromFinancialsTable = [
        {
          name: "Growth from Financials (YoY)",
          // value: withPercentWithDecimal(content?.codat_yoy_growth_financials) || '-'
          value: content?.codat_yoy_growth_financials != null && content?.codat_yoy_growth_financials !== '' && content?.codat_yoy_growth_financials !== -1
            ? withPercentWithDecimal(content?.codat_yoy_growth_financials) : '-'
        },
        {
          name: "Latest Financial Revenue Estimate (CODAT)",
          value: processValue(content?.latest_financial_rev_estimate)
        },
        {
          name: "Previous Financials Revenue Estimate (CODAT)",
          value: processValue(content?.previous_financial_rev_estimate)
        },
        {
          name: "Latest Financial Revenue Estimate with Growth (CODAT)",
          value: processValue(content?.latest_financial_rev_esti_with_growth)
        },
      ]
      setRevenueFromFinancials(revenueFromFinancialsTable)


      let revenueFromInvoicesTable = [
        {
          name: "Growth from Invoices (YoY)",
          // value: withPercentWithDecimal(content?.invoice_yoy_growth) || '-'
          value: content?.invoice_yoy_growth != null && content?.invoice_yoy_growth !== '' && content?.invoice_yoy_growth !== -1
            ? withPercentWithDecimal(content?.invoice_yoy_growth) : '-'
        },
        {
          name: "Latest Invoice Revenue Estimate (CODAT)",
          value: processValue(content?.latest_invoice_rev_estimate)
        },
        {
          name: "Previous Invoice Revenue Estimate (CODAT)",
          value: processValue(content?.previous_invoice_rev_estimate)
        },
        {
          name: "Latest Invoice Estimate with Growth (CODAT)",
          value: processValue(content?.latest_invoice_rev_estimate_with_growth)
        },
      ]
      setRevenueFromInvoices(revenueFromInvoicesTable)



      let debtTable = []
      debtTable = content?.monthly_debt_estimation?.map((item) => {
        return {
          "account": item?.account_number,
          "id": item?.id,
          "year": item?.year,
          "month": item?.month,
          "loan_credits": processValue(item?.loan_credits),
          "loan_repayments": processValue(item?.loan_repayments),
          "cc_debt": processValue(item?.cc_debt),
          "od_limit": item?.od_limit === 0 ? "$ 0" : item?.od_limit,
          "ecl_outstanding": processValue(item?.ecl_outstanding),
        }

      })
      setDebtTableData(debtTable.flat())

      let debtAggregationTable = [
        {
          nameD: "Final Bank Based Debt Estimate",
          valueD: processValue(content?.bank_based_estimated_debt)
        },
        {
          nameD: "Final Codat Financials Debt Estimate",
          valueD: processValue(content?.final_codat_financial_debt_estimate)
        },
        {
          nameD: "Final Debt Estimate with Bank & Financials",
          valueD: processValue(content?.debt_estimation)
        },
        {
          nameD: "Custom Debt Estimate",
          valueD: (<Flex justify={"space-between"} alignItems={"center"} onClick={() => handleDebtModal()} >
            {processValue(customDebtEditedValue)}<Icon cursor={"pointer"} as={BiSolidEdit} width="20px" height="20px" color="rgba(0, 162, 173, 1)" me={'5px'} /></Flex>
          )
        },

      ]
      setDebtAggregation(debtAggregationTable)


      let debtFromBankTable = [
        {
          name: "Loan Credits (Annualized)",
          value: processValue(content?.bank_based_loan_credits)
        },
        {
          name: "Loan Debits (Annualized)",
          value: processValue(content?.bank_based_debits)
        },
        {
          name: "ECL outstanding",
          value: processValue(content?.ecl_outstanding)
        },
        {
          name: "CC Payment",
          value: processValue(content?.bank_based_cc_debts)
        },
        {
          name: "OD Limit",
          value: processValue(content?.bank_based_od_limit)
        },
        {
          name: "Final bank based debt estimate",
          value: processValue(content?.bank_based_estimated_debt)
        },

      ]
      setDebtFromBank(debtFromBankTable)


      let debtFromCodatTable = [
        {
          name: "Credit Cards Outstanding Financials",
          value: processValue(content?.codat_cc_outstanding)
        },
        {
          name: "Short Term Debt",
          value: processValue(content?.short_term_debt)
        },
        {
          name: "Long Term Debt",
          value: processValue(content?.long_term_debt)
        },
        {
          name: "Final Codat Financials Debt Estimate",
          value: processValue(content?.final_codat_financial_debt_estimate)
        },
      ]
      setDebtFromCodat(debtFromCodatTable)




      let obj = {
        revenue: [
          {
            name: "Latest Quarter",
            value: getCurrencyValue(Math.round(content?.latest_quarter_revenue)) || '-',
            icon: MdTrendingUp

          },
          {
            name: "Previous Quarter",
            value: getCurrencyValue(Math.round(content?.previous_quarter_revenue)) || '-',
            icon: MdMultilineChart
          },
          {
            name: "Final Revenue",
            value: getCurrencyValue(Math.round(content?.bank_based_estimated_revenue)) || '-',
            icon: MdOutlineQueryStats
          }
        ],
        debt: [
          {
            name: "ECL Outstanding",
            value: processValue(content?.ecl_outstanding) || '-',
            icon: MdCurrencyExchange
          },
          {
            name: "Final Debt",
            value: processValue(content?.bank_based_estimated_debt) || '-',
            icon: MdOutlineTroubleshoot
          }
        ],
        risk: [
          {
            name: "AGGREGATED RISK PROFILE",
            value: content?.risk_tier === "Low-Medium" ? <Text color={'#F39035'}>Low-Medium</Text> : content?.risk_tier == null ? "-" : content?.risk_tier,
            icon: MdOutlineSpeed
          },
          {
            name: "SCORE",
            value: content?.risk_score || '-',
            icon: MdStarHalf
          }
        ]
      }
      setKpiData(obj)

      let riskData = [
        {
          name: "Years in Business",
          metric: <Text textAlign='right'>{content?.business_scorecard?.metric != null && content?.business_scorecard?.metric !== '' && content?.business_scorecard?.metric !== -1
            ? content?.business_scorecard?.metric?.toFixed(1)
            : '-'}</Text>,
          score: <Text textAlign='center'>{content?.business_scorecard?.score || '-'}</Text>,
          weight: <Text textAlign='center'>{content?.business_scorecard?.weight || '-'}</Text>,
          weight_rank: <Text textAlign='center'>{content?.business_scorecard?.weight_rank || "-"}</Text>
        },
        {
          name: "Current Debt/Revenue",
          metric: <Text textAlign='right'>{content?.dtr_scorecard?.metric != null && content?.dtr_scorecard?.metric !== '' && content?.dtr_scorecard?.metric !== -1
            ? `${content?.dtr_scorecard?.metric?.toFixed(2)} %`
            : '-'}</Text>,
          score: <Text textAlign='center'>{content?.dtr_scorecard?.score || '-'}</Text>,
          weight: <Text textAlign='center'>{content?.dtr_scorecard?.weight || '-'}</Text>,
          weight_rank: <Text textAlign='center'>{content?.dtr_scorecard?.weight_rank || "-"}</Text>
        },
        {
          name: "ADB to monthly Revenue Ratio",
          metric: <Text textAlign='right'>{content?.adb_to_monthly_revenue_ratio_scorecard?.metric != null && content?.adb_to_monthly_revenue_ratio_scorecard?.metric !== '' &&
            content?.adb_to_monthly_revenue_ratio_scorecard?.metric !== -1 ? `${content?.adb_to_monthly_revenue_ratio_scorecard?.metric?.toFixed(2)} %`
            : '-'}</Text>,
          score: <Text textAlign='center'>{content?.adb_to_monthly_revenue_ratio_scorecard?.score || '-'}</Text>,
          weight: <Text textAlign='center'>{content?.adb_to_monthly_revenue_ratio_scorecard?.weight || '-'}</Text>,
          weight_rank: <Text textAlign='center'>{content?.adb_to_monthly_revenue_ratio_scorecard?.weight_rank || "-"}</Text>
        },
        {
          name: "Bank Credits QtoQ (last 6 months)",
          metric: <Text textAlign='right'>{content?.qoq_growth_scorecard?.metric != null && content?.qoq_growth_scorecard?.metric !== '' && content?.qoq_growth_scorecard?.metric !== -1
            ? `${content.qoq_growth_scorecard.metric.toFixed(2)} %`
            : '-'}</Text>,
          score: <Text textAlign='center'>{content?.qoq_growth_scorecard?.score || '-'}</Text>,
          weight: <Text textAlign='center'>{content?.qoq_growth_scorecard?.weight || '-'}</Text>,
          weight_rank: <Text textAlign='center'>{content?.qoq_growth_scorecard?.weight_rank || "-"}</Text>
        },
        {
          name: "Days with -ve balance",
          metric: <Text textAlign='right'>{content?.negative_balance_days_scorecard?.metric != null && content?.negative_balance_days_scorecard?.metric !== '' && content?.negative_balance_days_scorecard?.metric !== -1 ? content.negative_balance_days_scorecard.metric.toFixed(0) : '-'}</Text>,
          score: <Text textAlign='center'>{content?.negative_balance_days_scorecard?.score || '-'}</Text>,
          weight: <Text textAlign='center'>{content?.negative_balance_days_scorecard?.weight || '-'}</Text>,
          weight_rank: <Text textAlign='center'>{content?.negative_balance_days_scorecard?.weight_rank || "-"}</Text>
        },
        {
          name: "Codat : Current Ratio",
          metric: <Text textAlign='right'>{content?.codat_current_ratio_scorecard?.metric != null && content?.codat_current_ratio_scorecard?.metric !== '' && content?.codat_current_ratio_scorecard?.metric !== -1 ? `${content.codat_current_ratio_scorecard.metric.toFixed(2)} %` : '-'}</Text>,
          score: <Text textAlign='center'>{content?.codat_current_ratio_scorecard?.score || '-'}</Text>,
          weight: <Text textAlign='center'>{content?.codat_current_ratio_scorecard?.weight || '-'}</Text>,
          weight_rank: <Text textAlign='center'>{content?.codat_current_ratio_scorecard?.weight_rank || "-"}</Text>,
        },
        {
          name: "Codat : EBITDA",
          metric: <Text textAlign='right'>{content?.codat_ebitda_scorecard?.metric != null && content?.codat_ebitda_scorecard?.metric !== '' && content?.codat_ebitda_scorecard?.metric !== -1
            ? `${content.codat_ebitda_scorecard.metric.toFixed(2)} %`
            : '-'}</Text>,
          score: <Text textAlign='center'>{content?.codat_ebitda_scorecard?.score || '-'}</Text>,
          weight: <Text textAlign='center'>{content?.codat_ebitda_scorecard?.weight || '-'}</Text>,
          weight_rank: <Text textAlign='center'>{content?.codat_ebitda_scorecard?.weight_rank || "-"}</Text>,
        },
        {
          name: "Codat : YoY Growth",
          metric: <Text textAlign='right'>{content?.codat_yoy_growth_scorecard?.metric != null && content?.codat_yoy_growth_scorecard?.metric !== '' && content?.codat_yoy_growth_scorecard?.metric !== -1 ? `${content?.codat_yoy_growth_scorecard?.metric?.toFixed(2)} %` : '-'}</Text>,
          score: <Text textAlign='center'>{content?.codat_yoy_growth_scorecard?.score || '-'}</Text>,
          weight: <Text textAlign='center'>{content?.codat_yoy_growth_scorecard?.weight || '-'}</Text>,
          weight_rank: <Text textAlign='center'>{content?.codat_yoy_growth_scorecard?.weight_rank || "-"}</Text>,
        },
        {
          name: "VC Funded Company",
          metric: content?.vc_funded_company_scorecard?.metric != null && content?.vc_funded_company_scorecard?.metric !== '' && content?.vc_funded_company_scorecard?.metric !== -1
            ? `${content?.vc_funded_company_scorecard?.metric}` : '-',
          score: <Text textAlign='center'>{content?.vc_funded_company_scorecard?.score || '-'}</Text>,
          weight: <Text textAlign='center'>{content?.vc_funded_company_scorecard?.weight || '-'}</Text>,
          weight_rank: <Text textAlign='center'>{content?.vc_funded_company_scorecard?.weight_rank || "-"}</Text>
        },
        {
          name: "Founder Profile",
          metric: content?.founder_profile_scorecard?.metric != null && content?.founder_profile_scorecard?.metric !== '' && content?.founder_profile_scorecard?.metric !== -1
            ? `${content?.founder_profile_scorecard?.metric}` : '-',
          score: <Text textAlign='center'>{content?.founder_profile_scorecard?.score || '-'}</Text>,
          weight: <Text textAlign='center'>{content?.founder_profile_scorecard?.weight || '-'}</Text>,
          weight_rank: <Text textAlign='center'>{content?.founder_profile_scorecard?.weight_rank || "-"}</Text>
        },
      ]

      setRiskTable(riskData)

      let estimate = [
        {
          name: "Average Risk Score",
          metric: content?.risk_score || '-'
        },
        {
          name: "Risk Grade",
          metric: content?.risk_tier || '-'
        },
        {
          name: "Total Revenue",
          metric: getCurrencyValue(Math.round(content?.revenue_estimation)) || '-'
        },
        {
          name: "Debt Capacity Multiplier",
          metric: `${content?.multiplier?.toFixed(1)} %` || '-'
        },
        // {
        //   name: "Calculated Debt Capacity",
        //   metric: processValue(content?.calculated_debt_capacity) || '-',
        // },
        {
          name: "Total Debt Capacity",
          metric: processValue(content?.total_debt_capacity) || '-',
        },
        {
          name: "Final Debt Estimate",
          metric: processValue(content?.debt_estimation) || '-',
        },
        {
          name: "Final Line Approval",
          metric: processValue(content?.final_approved_line) || '-',
        },
      ]
      setLineTable(estimate)

      const kybData = content?.bank_based_revenue_estimation?.map((ele) => {
        return {
          institution_name: ele?.institution_name || "-",
          number: ele?.account_number || "-",
          account_owner_name: ele?.account_owner_name || "-",
          name: ele?.account_name || "-",
          status: ele?.kyb_status ? <Text color={"rgba(0, 162, 173, 1)"}>Passed</Text> : <Text color={"#E04F4F"}>Failed</Text>,
          level: ele?.kyb_level || "-",
          priority: ele?.ach_priority === 1 ? <Text color={"rgba(0, 162, 173, 1)"}>1</Text> : ele?.ach_priority === 2 ? <Text color={"#FFCE20"}>2</Text> : ele?.ach_priority === 3 ? <Text color={"#FFCE20"}>3</Text> : "-"
        }
      })
      setKybTable(kybData)

      const bankRevenue = content?.bank_based_revenue_estimation?.map((ele) => {
        return {
          name: ele?.account_number || "-",
          estimate: processValue(ele?.bank_based_revenue_estimate)
        }

      })
      setRevenueBankTableData(bankRevenue)

      const bankDebt = content?.bank_based_revenue_estimation?.map((ele) => {
        return {
          name: ele?.account_number || "-",
          estimate: processValue(ele?.bank_based_debt_estimate)
        }
      })
      setDebtBankTableData(bankDebt)

      const declineReasonArray = content?.decline_reason ? content?.decline_reason.slice(2, -2).split("', '") : [];

      const status = (value) => {

        if (value === "Approved") {
          return (<Flex><Icon as={MdCheckCircle} width="20px" height="20px" color="rgba(0, 162, 173, 1)" me={'5px'} />Approved</Flex>)
        } else if (value === "Declined") {
          return (<Flex><Icon as={MdCancel} width="20px" height="20px" color="rgba(224, 79, 79, 1)" me={'5px'} />Declined</Flex>)
        } if (value === "In Progress") {
          return (<Flex><Icon as={MdOutlineError} width="20px" height="20px" color="#FEE27F)" me={'5px'} />In Progress</Flex>)
        } if (value === "Pending") {
          return (<Flex><Icon as={MdPending} width="20px" height="20px" color="#C3B7E5" me={'5px'} />Pending</Flex>)
        } if (value === "Manual Review") {
          return (<Flex><Icon as={MdSearch} width="18px" height="18px" p={'2px'} bg={'rgba(0, 162, 173, 1)'} borderRadius={'50%'} color="rgba(255,255,255)" me={'5px'} />Manual Review</Flex>)
        } else {
          return value
        }
      }


      let summary = [
        {
          name: "Application ID",
          value: content?.application?.application_id || "-"
        },
        {
          name: "Business Name",
          value: content?.business_name || "-"
        },
        {
          name: "Customer ID",
          value: content?.customer_id || "-"
        },
        {
          name: "Data Period Considered (Max)",
          value: content?.period_of_study_days || "-"
        },
        {
          name: "Final Revenue Estimate",
          value: content?.custom_decision?.length > 0 && content?.custom_decision[0]?.custom_revenue_estimate !== null ? processValue(content?.custom_decision[0]?.custom_revenue_estimate) : processValue(content?.revenue_estimation)
        },
        {
          name: "Final Debt Estimate",
          value: content?.custom_decision?.length > 0 && content?.custom_decision[0]?.custom_debt_estimate !== null ? processValue(content?.custom_decision[0]?.custom_debt_estimate) : processValue(content?.debt_estimation)
        },
        {
          name: "L3M Average Daily Balance",
          value: processValue(content?.l3m_avg_balance)
        },
        {
          name: "Latest Balance",
          value: processValue(content?.latest_balance)
        },
        {
          name: "Monthly Avg Burn (Bank)",
          value: processValue(content?.avg_burn)
        },
        {
          name: "Bank Based Runway",
          value: content?.runway_month != null && content?.runway_month !== '' && content?.runway_month !== -1
            ? content?.runway_month?.toFixed(1) : '-'
        },
        {
          name: "Monthly Avg Burn (Codat)",
          value: content?.codat_based_burn != null && content?.codat_based_burn !== '' && content?.codat_based_burn !== -1
            ? processValue(content?.codat_based_burn) : '-'
        },
        {
          name: "Codat Based Runway",
          value: content?.codat_based_runway != null && content?.codat_based_runway !== '' && content?.codat_based_runway !== -1
            ? content?.codat_based_runway?.toFixed(1) : '-'
        },
        {
          name: "Manual Override",
          value: content?.triggered_by === "Manual" ? "Yes" : "No" || "-"
        },
        {
          name: "Risk Score",
          value: content?.risk_score || "-"
        },

        {
          name: "Risk Tier",
          value: content?.risk_tier || "-"
        },

        {
          name: "Final Line Approval",
          value: processValue(content?.final_approved_line)
        },
        //#ECL-1071 added final offer and customer requested amount fileds 
        {
          name:"Final Offer",
          value: processValue(content?.final_offer) ,
        },
        {
          name:"Customer Requested Amount",
          value: content?.customer_requested_amount ? processValue(content?.customer_requested_amount) : "Not Available",
        },
        {
          name: "Pricing",
          value: content?.pricing ? (content?.pricing?.toFixed(1) + ' %') : "NA"
        },

        {
          name: "KYB Status",
          value: content?.is_kyb_passed ? <Text color={"rgba(0, 162, 173, 1)"}>Passed</Text> : <Text color={"#E04F4F"}>Failed</Text>,
        },
        {
          name: "KYB Level",
          value: content?.kyb_level || "-"
        },
        {
          name: "ACH priority 1",
          value: content?.bank_based_revenue_estimation.map((ele) => ele.ach_priority === 1 ? `${ele?.institution_name || '-'} ${' | ' + ele?.account_number || '-'}` : "")
        },
        {
          name: "ACH priority 2",
          value: content?.bank_based_revenue_estimation.map((ele) => ele.ach_priority === 2 ? `${ele?.institution_name || '-'} ${' | ' + ele?.account_number || '-'}` : "")
        },
        {
          name: "Final Risk Decision",
          value: status(content?.loan_status) || "-"
        },
        {
          name: "Decline Reason 1",
          value: declineReasonArray[0] ? declineReasonArray[0] : "-"
        },
        {
          name: "Decline Reason 2",
          value: declineReasonArray[1] ? declineReasonArray[1] : "-"
        },

      ]
      setSummaryTable(summary)

      let revenuePopupOptions = [

        {
          label: "Latest 365 days estimate with growth" + " " + "|" + " " + processValue(content?.latest_365_days_growth_estimate),
          value: (content?.latest_365_days_growth_estimate)
        },
        {
          label: "Latest 180 days annualized estimate with growth" + " " + "|" + " " + processValue(content?.latest_180_days_growth_estimate),
          value: (content?.latest_180_days_growth_estimate)
        },
        {
          label: "Latest 12 calendar months estimate" + " " + "|" + " " + processValue(content?.latest_12_month_estimate),
          value: (content?.latest_12_month_estimate)
        },
        {
          label: "Latest 12 calendar months estimate with growth" + " " + "|" + " " + processValue(content?.latest_12_month_growth),
          value: (content?.latest_12_month_growth)
        },
        {
          label: "Latest 6 calendar months annualized estimate" + " " + "|" + " " + processValue(content?.latest_6_month_estimate),
          value: (content?.latest_6_month_estimate)
        },
        {
          label: "Latest 6 calendar months annualized estimate with growth" + " " + "|" + " " + processValue(content?.latest_6_month_growth_estimate),
          value: (content?.latest_6_month_growth_estimate)
        },
        {
          label: "Latest Financial Revenue Estimate (CODAT)" + " " + "|" + " " + processValue(content?.latest_financial_rev_estimate),
          value: (content?.latest_financial_rev_estimate)
        },
        {
          label: "Previous Financials Revenue Estimate (CODAT)" + " " + "|" + " " + processValue(content?.previous_financial_rev_estimate),
          value: (content?.previous_financial_rev_estimate)
        },
        {
          label: "Latest Financial Revenue Estimate with Growth (CODAT)" + " " + "|" + " " + processValue(content?.latest_financial_rev_esti_with_growth),
          value: (content?.latest_financial_rev_esti_with_growth)
        },
        {
          label: "Latest Invoice Revenue Estimate (CODAT)" + " " + "|" + " " + processValue(content?.latest_invoice_rev_estimate),
          value: (content?.latest_invoice_rev_estimate)
        },
        {
          label: "Previous Invoice Revenue Estimate (CODAT)" + " " + "|" + " " + processValue(content?.previous_invoice_rev_estimate),
          value: (content?.previous_invoice_rev_estimate)
        },
        {
          label: "Latest Invoice Estimate with Growth (CODAT)" + " " + "|" + " " + processValue(content?.latest_invoice_rev_estimate_with_growth),
          value: (content?.latest_invoice_rev_estimate_with_growth)
        },

      ]
      setCustomRevenuePopupOptions(revenuePopupOptions)

      if (customDecision?.length > 0) {

        let revenueValue = 0;
        revenuePopupOptions?.length > 0 && revenuePopupOptions?.forEach((item) => {
          if (item?.label?.substring(0, item?.label?.lastIndexOf(" |")).trim() === customDecision[0]?.custom_revenue_variable_name) {
            revenueValue = item?.value
          }
        })

        setCustomRevenueDetails({
          revenueVariable: customDecision[0]?.custom_revenue_variable_name,
          revenuePercent: customDecision[0]?.custom_revenue_perc,
          totalRevenueEstimate: customDecision[0]?.custom_revenue_estimate,
          revenueCurrentValue: revenueValue
        })
        setInputValueIndicator(customDecision[0]?.custom_revenue_perc)
      }

      content?.custom_decision?.length > 0 && revenuePopupOptions?.length > 0 && revenuePopupOptions?.map((item) => {

        return (
          item?.label?.substring(0, item?.label?.lastIndexOf(" |")).trim() === content?.custom_decision[0]?.custom_revenue_variable_name ? setSelectedCustomRevenuePopupOptionsIndicator((item?.label)) : ''
        )
      })

    }).catch((error) => {
      setError(true)
      setLoader(false)
    });
  }

  const getTransactionDetails = () => {
    let newSelectedDecision = [];
    let completedRequests = 0;
    const totalRequests = selectedApplications ? selectedApplications.length : 0;
    setLoader(true);

    selectedApplications?.forEach((item) => {
      service
        .get(`${apiendpoints.APPLICATIONS}/${item?.globalRecordId}/decisions`)
        .then((res) => {
          const { content } = res;
          const arr = content.map((item) => item?.decision_id).slice(0, 50);
          newSelectedDecision.push({
            application_id: item?.globalRecordId,
            business_name: item?.globalBusinessName,
            originalApp_id: item?.globalApplicationId,
            decision_ids: arr,
          });

          completedRequests++;

          if (completedRequests === totalRequests) {
            newSelectedDecision.sort((a, b) => {
              const indexA = selectedApplications.findIndex(
                (app) => app.globalRecordId === a.application_id
              );
              const indexB = selectedApplications.findIndex(
                (app) => app.globalRecordId === b.application_id
              );
              return indexA - indexB;
            });

            setLoader(false);
            setActiveAppId(newSelectedDecision[0]?.application_id);
            setDecisionId(newSelectedDecision[0]?.decision_ids[0]);
            if (newSelectedDecision.length > 0) {
              dispatch(mapSelectedDecisions(newSelectedDecision));
              localStorage.setItem(
                localStorageKeys.SELECTED_DECISIONS,
                JSON.stringify(newSelectedDecision)
              );
            }
          }
        })
        .catch((error) => {
          completedRequests++;

          if (completedRequests === totalRequests) {
            setLoader(false);
          }

          console.error("Error fetching decision ids:", error);
        });
    });
  };






  const calculation = () => {
    if (inputValue !== "" && inputValue >= minPercentage && inputValue <= maxPercentage) {
      const result = (Number(selectedCustomRevenuePopupOptions.value) * inputValue) / 100 + Number(selectedCustomRevenuePopupOptions.value)
      setUpdatedValue(result)

      document.getElementById('tick-icon').style.color = 'rgba(0, 0, 0, 0.3)';
      document.getElementById('tick-icon').style.cursor = 'not-allowed';
      document.getElementById('tick-icon').style.pointerEvents = 'none';
    }
    else if (inputValue !== "" && (inputValue <= minPercentage || inputValue >= maxPercentage)) {
      toast.error("Enter a value between " + minPercentage + "% and " + maxPercentage + "%", {
        toastId: "minMaxId",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        icon: <Icon as={MdCancel} width='24px' height='24px' color={'rgba(224, 79, 79, 1)'} cursor={'pointer'} />
      });

    } else if (selectedCustomRevenuePopupOptions?.value === 'custom' || inputValue == "") {
      document.getElementById('tick-icon').style.color = 'rgba(0, 0, 0, 0.3)';
      document.getElementById('tick-icon').style.cursor = 'not-allowed';
      document.getElementById('tick-icon').style.pointerEvents = 'none';
    } else {
      document.getElementById('tick-icon').style.color = '#00A2AD';
      document.getElementById('tick-icon').style.cursor = 'pointer';
      document.getElementById('tick-icon').style.pointerEvents = 'auto';
    }
  }


  const handleFirstPageClick = () => {
    setCurrentPage(1);
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (currentPage < totalPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLastPageClick = () => {
    setCurrentPage(totalPage);
  };

  const previousPage = currentPage > 1 ? currentPage - 1 : null;

  const nextPage = currentPage < totalPage ? currentPage + 1 : null;

  const handleFirstPageClickD = () => {
    setCurrentPageD(1);
  };

  const handlePreviousPageClickD = () => {
    if (currentPageD > 1) {
      setCurrentPageD(currentPageD - 1);
    }
  };

  const handleNextPageClickD = () => {
    if (currentPageD < totalPageD) {
      setCurrentPageD(currentPageD + 1);
    }
  };

  const handleLastPageClickD = () => {
    setCurrentPageD(totalPageD);
  };


  const runDecision = () => {

    const payload = {
      "is_custom": isCustom,
      "custom_type": customType || null,
      "ref_decision_id": decisionId || null,
      ...(customType == "Revenue" || customType == "Both") && {
        "custom_rev_variable_name": selectedCustomRevenuePopupOptionsIndicator.substring(0, selectedCustomRevenuePopupOptionsIndicator.lastIndexOf(" |")).trim() || null,
        "custom_rev_perc_change": Number(inputValueIndicator) || null,
        "custom_rev_variable_value": customRevenueEditedValue || null,
      },
      ...(customType == "Debt" || customType == "Both") && {
        "custom_bank_based_perc_change": Number(bankBasedInputValue) || null,
        "custom_bank_based_value": bankBasedUpdatedValue || null,
        "custom_codat_based_per_change": Number(codatBasedInputValue) || null,
        "custom_codat_based_value": codatBasedUpdatedValue || null,
        "custom_value": Number(debtCustomInputValue) || null,
        "custom_debt_value": totalBasedUpdatedValue || null,
      },
    }

    var data = new FormData()
    fileCommentArray.forEach((proof, index) => {
      data.append("file", proof.file)
      data.append("comment", proof.comment)
    })

    service.post(`${apiendpoints?.DECISIONS}/${activeAppId}/run-decision`, payload).then((res) => {

      setResDecisionId(res?.content?.decision_id)

      toast.success("Decision Run Requested. It may take up to 5 minutes to process the Decision Summary.", {
        toastId: "successId",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        icon: <Icon as={CheckCircleIcon} width='20px' height='20px' color={'#00A2AD'} cursor={'pointer'} />
      });

      if (fileCommentArray.length > 0) {
        service.post(`${apiendpoints?.DECISIONS}/${res?.content?.decision_id}/custom-debt-proof`, data).then((res) => {

        }).catch((error) => {

        })
      }

    }).catch((error) => {
      toast.error("Error Notification !", {
        toastId: "errorId",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        icon: <Icon as={MdCancel} width='24px' height='24px' color={'rgba(224, 79, 79, 1)'} cursor={'pointer'} />
      });
    })

  }

  const fetchCustomDebtProofs = () => {
    setFileCommentLoader(true);
    service.get(`${apiendpoints?.DECISIONS}/${decisionId}/custom-debt-proof`).then((res) => {
      const { content } = res;
      const fetchFilesPromises = content.map(item => {
        return fetch(item.debt_proof_url)
          .then(response => response.blob())
          .then(blob => new File([blob], item.file_name, { lastModified: new Date().getTime(), type: blob.type }));
      });


      Promise.all(fetchFilesPromises)
        .then(fileObjects => {
          const updatedFileDataArray = content.map((item, index) => ({
            id: item.id,
            file: fileObjects[index],
            fileName: item.file_name + "." + fileObjects[index].type.split("/")[1],
            comment: item.comment,
            riskId: item.risk_decision?.id,
          }));

          setFileDataArray1(updatedFileDataArray);
          setFileCommentArray1(updatedFileDataArray);
          setFileCommentLoader(false);

        })
        .catch(error => {
          console.error('Error', error);
        });


    }).catch((error) => {
      console.error("Error", error);
      setFileCommentLoader(false);
    });
  };


  const deleteCustomDebtProofs = () => {
    service.delete(`${apiendpoints?.DECISIONS}/${decisionId}/custom-debt-proof`).then((res) => {

      setFileCommentArray([]);

    }).catch((error) => {
      console.error("Error fetching decision ids:", error);
    });

  }


  const deleteParticularCustomDebtProof = (riskId, id) => {
    const updatedFileDataArray = fileDataArray.filter(fileData => fileData.id !== id);
    const updatedFileCommentArray = fileCommentArray.filter(fileComment => fileComment.id !== id);
    setFileDataArray(updatedFileDataArray);
    setFileCommentArray(updatedFileCommentArray);

    if (riskId) {
      service.delete(`${apiendpoints?.DECISIONS}/${riskId}/custom-debt-proof/${id}`).then((res) => {
        setFileDataArray(prevFileDataArray => prevFileDataArray.filter(file => !(file.riskId === riskId && file.id === id)));
        setFileCommentArray(prevFileCommentArray => prevFileCommentArray.filter(fileComment => fileComment.id !== id));
      }).catch((error) => {
        console.error("Error fetching decision ids:", error);
      });
    }
  }


  const fetchFraudCheckSummary = () => {
    service.get(`${apiendpoints?.DECISIONS}/${decisionId}/fraud-check`).then((res) => {
      const { content } = res;

      const formattedData = mapDuplicationResToTable(content);
      setDuplicationRules(formattedData);

      const formattedData1 = mapThresholdResToTable(content);
      setThresholdRules(formattedData1);



    }).catch((error) => {
      console.error("Error fetching fraud-check summary", error);
    });
  }





  const updateParticularCustomDebtProof = (riskId, id, event) => {
    event.preventDefault();
    const inputElement = document.createElement("input");
    inputElement.type = "file";
    inputElement.accept = "image/*, application/pdf";
    inputElement.onchange = (e) => handleReplaceImageChange(riskId, id, e);
    inputElement.click();
  };

  const handleReplaceImageChange = (riskId, id, event) => {
    const selectedImage = event.target.files[0];
    const nameFile = event.target.files[0].name;

    const fileIndex = fileDataArray.findIndex(file => file.id === id);

    if (fileIndex !== -1 && selectedImage) {
      const updatedFileDataArray = [...fileDataArray];
      updatedFileDataArray[fileIndex].file = selectedImage;
      updatedFileDataArray[fileIndex].fileName = nameFile;
      const comment = updatedFileDataArray[fileIndex].comment || '';

      setFileDataArray(updatedFileDataArray);
    }
  };


  const onSubmitFileComment = (fileId, riskId) => {
    const uploadedFile = fileDataArray.find(file => file.id === fileId);
    if (uploadedFile) {

      if (!uploadedFile.comment || uploadedFile.comment.trim() === '') {
        setCommentIsRequired(prevState => ({
          ...prevState,
          [fileId]: true
        }));
        setTimeout(() => {
          setCommentIsRequired(prevState => ({
            ...prevState,
            [fileId]: false
          }));
        }, 3000);
        return;
      }
      const existingFileIndex = fileCommentArray.findIndex(item => item.id === uploadedFile.id);
      if (existingFileIndex !== -1) {
        const updatedFileCommentArray = [...fileCommentArray];
        updatedFileCommentArray[existingFileIndex] = { id: uploadedFile.id, file: uploadedFile.file, fileName: uploadedFile.fileName, comment: uploadedFile.comment };
        setFileCommentArray(updatedFileCommentArray);
      } else {
        setFileCommentArray([...fileCommentArray, { id: uploadedFile.id, file: uploadedFile.file, fileName: uploadedFile.fileName, comment: uploadedFile.comment }]);
      }

      if (riskId) {
        const formData = new FormData();
        formData.append("comment", uploadedFile.comment);
        formData.append("file", uploadedFile.file);
        service.put(`${apiendpoints?.DECISIONS}/${riskId}/custom-debt-proof/${fileId}`, formData)
          .then((res) => {
          })
          .catch((error) => {
            console.error("Error updating custom debt proof:", error);
          });
      }

    }
    setIsEditing(prevState => ({ ...prevState, [fileId]: true }));
  };


  const previousPageD = currentPageD > 1 ? currentPageD - 1 : null;

  const nextPageD = currentPageD < totalPageD ? currentPageD + 1 : null;

  useEffect(() => {
    setActiveAppId(selectedDecisions && selectedDecisions[0]?.application_id)
    setDecisionId(selectedDecisions[0]?.decision_ids[0])
  }, [])

  useEffect(() => {
    var decisionSelected = localStorage.getItem(localStorageKeys?.SELECTED_DECISIONS)
    if (decisionSelected && (JSON.parse(decisionSelected).length > 0)) {
      dispatch(mapSelectedDecisions(JSON.parse(decisionSelected)))
      setActiveAppId(JSON.parse(decisionSelected)[0]?.application_id)
    }
  }, [])

  useEffect(() => {
    if (activeAppId && decisionId) {
      fetchData()
      fetchFinancialRangeData()
      setSelectedCustomRevenuePopupOptionsIndicator('')
    }
  }, [decisionId])

  useEffect(() => {
    if (history && history.location.search == "?type=plaid" && selectedApplications && selectedApplications.length > 0) {
      getTransactionDetails()
    }
  }, [history, selectedApplications])




  useEffect(() => {
    window.scrollTo(0, 0);
    const updateHeight = () => {
      if (document.getElementById('getHeight')) {
        const newHeight = document.getElementById('getHeight').offsetHeight;
        setHeight(newHeight);
      }
    };

    setTimeout(() => {
      updateHeight();
    }, 0);
  }, [location.pathname, selectedDecisions.length]);

  useEffect(() => {
    const paddingTop = document.getElementById('paddingTop');
    if (paddingTop) {
      paddingTop.style.padding = `${height - 37}px 0 0 0`;
    }
  }, [height]);

  useEffect(() => {
    setTimeout(() => {
      setRevenueAggregation(prevState => {
        const updatedRevenueAggregation = prevState.map(item => {
          if (item.nameC === "Custom Revenue Estimate") {
            return {
              ...item,
              valueC: (
                <Flex justify={"space-between"} gap={"10px"} alignItems={"center"}>
                  {processValue(customRevenueEditedValue)}
                  <Icon onClick={() => handleRevenueModal()} cursor={"pointer"} as={BiSolidEdit} width="20px" height="20px" color="rgba(0, 162, 173, 1)" me={'5px'} />
                </Flex>
              )
            };
          }
          return item;
        });
        return updatedRevenueAggregation;
      });
    }, 1000);

  }, [customRevenueEditedValue]);



  useEffect(() => {
    setTimeout(() => {
      setDebtAggregation(prevState => {
        const updatedDebtAggregation = prevState.map(item => {
          if (item.nameD === "Custom Debt Estimate") {
            return {
              ...item,
              valueD: (
                <Flex justify={"space-between"} gap={"10px"} alignItems={"center"}>
                  {processValue(customDebtEditedValue)}
                  <Icon onClick={() => handleDebtModal()} cursor={"pointer"} as={BiSolidEdit} width="20px" height="20px" color="rgba(0, 162, 173, 1)" me={'5px'} />
                </Flex>
              )
            };
          }
          return item;
        });
        return updatedDebtAggregation;
      });
    }, 1000);

  }, [customDebtEditedValue]);



  useEffect(() => {
    if ((customRevenueEditedValue !== '-' && customRevenueEditedValue !== null && customRevenueEditedValue !== '') || (customDebtEditedValue !== '-' && customDebtEditedValue !== null && customDebtEditedValue !== '')) {
      setIsCustom(true);
    } else {
      setIsCustom(false);
    }
  }, [customRevenueEditedValue, customDebtEditedValue])

  useEffect(() => {
    if (customRevenueEditedValue !== '-' && customRevenueEditedValue !== null && customRevenueEditedValue !== '' && customDebtEditedValue !== '-' && customDebtEditedValue !== null && customDebtEditedValue !== '') {
      setCustomType('Both');
    } else if (customRevenueEditedValue !== '-' && customRevenueEditedValue !== null && customRevenueEditedValue !== '') {
      setCustomType('Revenue');
    } else if (customDebtEditedValue !== '-' && customDebtEditedValue !== null && customDebtEditedValue !== '') {
      setCustomType('Debt');
    }
  }, [customRevenueEditedValue, customDebtEditedValue]);


  useEffect(() => {
    if (fileDataArray1.length > 0 && fileCommentArray1.length > 0) {
      const updatedFileDataArray = fileDataArray.filter(data => !fileDataArray1.some(data1 => data1.id === data.id));
      const updatedFileCommentArray = fileCommentArray.filter(comment => !fileCommentArray1.some(comment1 => comment1.id === comment.id));

      setFileDataArray([...fileDataArray1, ...updatedFileDataArray]);
      setFileCommentArray([...fileCommentArray1, ...updatedFileCommentArray]);
    }
  }, [fileDataArray1, fileCommentArray1]);



  useEffect(() => {

    if (customData.bankBasedInputValue !== null && customData.bankBasedInputValue !== undefined) {
      setBankBasedInputValue(customData.bankBasedInputValue);
    }

    if (customData.codatBasedInputValue !== null && customData.codatBasedInputValue !== undefined) {
      setCodatBasedInputValue(customData.codatBasedInputValue);
    }

    if (customData.bankBasedUpdatedValue !== undefined) {
      setBankBasedUpdatedValue(customData.bankBasedUpdatedValue);
    }

    if (customData.codatBasedUpdatedValue !== null && customData.codatBasedUpdatedValue !== undefined) {
      setCodatBasedUpdatedValue(customData.codatBasedUpdatedValue);
    }

    if (customData.debtCustomInputValue !== null && customData.debtCustomInputValue !== undefined) {
      setDebtCustomInputValue(customData.debtCustomInputValue);
    }

    if (customData.totalBasedUpdatedValue !== null && customData.totalBasedUpdatedValue !== undefined) {
      setTotalBasedUpdatedValue(customData.totalBasedUpdatedValue);
    }

  }, [customData]);


  useEffect(() => {
    setBankBasedInputValue('')
    setCodatBasedInputValue('')
    setDebtCustomInputValue('')
    setBankBasedUpdatedValue('')
    setCodatBasedUpdatedValue('')
    setTotalBasedUpdatedValue('')
    setSelectedCustomRevenuePopupOptions({ label: 'Select', value: 'custom' })
    setInputValue('')
    setUpdatedValue('')
    setMinPercentage('')
    setMaxPercentage('')
    setCustomRevenueDetails('')
    setCustomData('')

  }, [decisionId])

  useEffect(() => {
    if (decisionId) {
      fetchFraudCheckSummary()
    }
  }, [decisionId])



  return (
    <Box id={'paddingTop'} pt={{ base: "140px", md: "140px", xl: "140px" }}>
      <FixedHeaderTop
        setCustomData={setCustomData}
        setCustomRevenueDetails={setCustomRevenueDetails}
        setSelectedCustomRevenuePopupOptions={setSelectedCustomRevenuePopupOptions}
        setInputValue={setInputValue}
        setUpdatedValue={setUpdatedValue}
        setMinPercentage={setMinPercentage}
        setMaxPercentage={setMaxPercentage}
        setBankBasedInputValue={setBankBasedInputValue}
        setCodatBasedInputValue={setCodatBasedInputValue}
        setDebtCustomInputValue={setDebtCustomInputValue}
        setBankBasedUpdatedValue={setBankBasedUpdatedValue}
        setCodatBasedUpdatedValue={setCodatBasedUpdatedValue}
        setTotalBasedUpdatedValue={setTotalBasedUpdatedValue}
        customRevenueEditedValue={customRevenueEditedValue} customDebtEditedValue={customDebtEditedValue} showRunDecisionBtn={!loanStatus} runDecision={runDecision} decisionId={decisionId} setDecisionId={setDecisionId} activeAppId={activeAppId} setActiveAppId={setActiveAppId} />
      {
        loader ? <LazyLoader view="table" /> :
          loanStatus ? <DisclaimerPage disclaimerText={disclaimerText} loanStatusText={loanStatusText} failedStatusText={failedStatusText} /> :
            error ? <SomethingWrong /> :
              (activeAppId === null || decisionId === undefined || selectedApplications.some(app => app.application_id?.globalRecordId === activeAppId && app.decision_ids?.length === 0)) ?
                <NoResults /> : <>
                  <Box my={'28px'}> <Period months={months} decisionRunDate={decisionRunDate} /></Box>
                  <Text mb={'20px'}>
                    Data Sources Used | {dataSourceText}
                  </Text>
                  <Box>
                    <Box pb={{ base: "130px", md: "20px", xl: "20px" }}>
                      <Text color='black' fontSize='18px' fontWeight='700'>KYB Status & ACH Priority</Text>
                    </Box>
                    <Flex mb='20px' direction={{ base: "column", md: "row" }} spacing={{ base: "20px", xl: "20px" }}>
                      <Box flex={{ base: "none", md: 1 }}>
                        <ColumnsTable columnsData={eclKybColumn} tableData={kybTable} showThead={true} />
                      </Box>
                    </Flex>
                  </Box>
                  <Box mt={'40px'}>
                    <Box pb={{ base: "130px", md: "20px", xl: "20px" }}>
                      <Text color='black' fontSize='18px' fontWeight='700'>Revenue Estimation </Text>
                    </Box>
                    <SimpleGrid
                      mb='20px'
                      columns={{ sm: 1, md: 2, lg: 3, xl: 3, "2xl": 4 }}
                      spacing={{ base: "20px", xl: "20px" }}>
                      {kpiData?.revenue?.map((kpi, index) => (
                        <MiniStatistics key={index} startContent={<IconBox w='56px' h='56px' bg={boxBg} icon={<Icon w='32px' h='32px' as={kpi.icon} color={brandColor} />} />}
                          name={kpi.name}
                          value={kpi.value}
                        />
                      ))}
                    </SimpleGrid>
                    <Flex
                      className="custom-scrollbar"
                      direction={{ base: "column", md: "row" }}
                      spacing={{ base: "20px", xl: "20px" }}
                    >
                      <Box flex={{ base: "none", md: 1 }}>
                        <ColumnsTable
                          titleData="Bank based Revenue Estimation"
                          columnsData={eclBankColumn}
                          tableData={revenueBankTableData}
                          showThead={false}
                        />
                      </Box>
                      <Box flex={{ base: "none", md: 3 }} ml='20px' overflow='auto' >
                        <ColumnsTable
                          columnsData={eclColumnsDataRevenue}
                          tableData={revenueTableData.slice((currentPage - 1) * revenueResultsPerPage, currentPage * revenueResultsPerPage)}
                          showThead={true}
                        />
                        <Flex className="pagination-container" alignItems={'center'} justify={'flex-end'} gap={'20px'} my="25px" whiteSpace={'nowrap'}>
                          <Flex gap={'20px'} bg={'white'} p={'5px 15px'} borderRadius={'0.375rem'} className="ecl-border-ui">
                            <button style={{ display: 'flex', alignItems: 'center' }} onClick={handleFirstPageClick} className={`button ${currentPage === 1 ? 'disabled' : ''}`}><Icon as={MdKeyboardDoubleArrowLeft} width='20px' height='20px' color='inherit' /> </button><span className="vertical-divider-line">&#124;</span>

                            <button style={{ display: 'flex', alignItems: 'center' }} onClick={handlePreviousPageClick} className={`button ${previousPage == null ? 'disabled' : ''}`}> <Icon as={MdNavigateBefore} width='20px' height='20px' color='inherit' /> </button><span className="vertical-divider-line">&#124;</span>

                            <span><strong>{currentPage} of {totalPage} </strong></span><span className="vertical-divider-line">&#124;</span>

                            <button style={{ display: 'flex', alignItems: 'center' }} onClick={handleNextPageClick} className={`button ${nextPage === null ? 'disabled' : ''}`}> <Icon as={MdNavigateNext} width='20px' height='20px' color='inherit' />  </button><span className="vertical-divider-line">&#124;</span>

                            <button style={{ display: 'flex', alignItems: 'center' }} onClick={handleLastPageClick} className={`button ${currentPage === totalPage ? 'disabled' : ''}`}> <Icon as={MdKeyboardDoubleArrowRight} width='20px' height='20px' color='inherit' /> </button>
                          </Flex>
                        </Flex>
                      </Box>
                    </Flex>
                  </Box>


                  {/* ---------------------------------------------------------------New -Revenue Estimation - section --------------------------------------------------------------- */}


                  <Flex gap={'20px'}>
                    <Box flex={{ base: "none", md: 1 }} >

                      <Box flex={{ base: "none", md: 1 }}>
                        <ColumnsTable
                          columnsData={eclRevenueAggregation}
                          tableData={revenueAggregation}
                          showThead={true}
                          selectedCustomRevenuePopupOptionsIndicator={selectedCustomRevenuePopupOptionsIndicator}
                        />
                      </Box>

                    </Box>
                    <Box flex={{ base: "none", md: 1 }} >

                      <Box flex={{ base: "none", md: 1 }}>
                        <ColumnsTable
                          columnsData={eclRevenueFromBank}
                          tableData={revenueFromBank}
                          showThead={true}
                        />
                      </Box>
                    </Box>
                  </Flex>

                  <Flex gap={'20px'} my={'20px'}>
                    <Box flex={{ base: "none", md: 1 }} >

                      <Box flex={{ base: "none", md: 1 }}>
                        <ColumnsTable
                          columnsData={eclRevenueFromFinancials}
                          tableData={RevenueFromFinancials}
                          showThead={true}
                        />
                      </Box>

                    </Box>
                    <Box flex={{ base: "none", md: 1 }} >

                      <Box flex={{ base: "none", md: 1 }}>
                        <ColumnsTable
                          columnsData={eclRevenueFromInvoices}
                          tableData={RevenueFromInvoices}
                          showThead={true}
                        />
                      </Box>
                    </Box>
                  </Flex>

                  {/* ----------------------------------------------------------------------------------------------------------------------------------------------------- */}

                  <Box mt={'40px'}>
                    <Box pb={{ base: "130px", md: "20px", xl: "20px" }}>
                      <Text color='black' fontSize='18px' fontWeight='700'>Debt Estimation</Text>
                    </Box>
                    <SimpleGrid
                      mb='20px'
                      columns={{ sm: 1, md: 2, lg: 3, xl: 3, "2xl": 4 }}
                      spacing={{ base: "20px", xl: "20px" }}>
                      {kpiData?.debt?.map((kpi, index) => (
                        <MiniStatistics key={index} startContent={<IconBox w='56px' h='56px' bg={boxBg} icon={<Icon w='32px' h='32px' as={kpi.icon} color={brandColor} />} />}
                          name={kpi.name}
                          value={kpi.value}
                        />
                      ))}
                    </SimpleGrid>
                    <Flex
                      className="custom-scrollbar"
                      direction={{ base: "column", md: "row" }}
                      spacing={{ base: "20px", xl: "20px" }}
                    >
                      <Box flex={{ base: "none", md: 1 }}>
                        <ColumnsTable
                          titleData="Bank based Debt Estimates"
                          columnsData={eclBankColumn}
                          tableData={debtTablBankeData}
                          showThead={false}
                        />
                      </Box>
                      <Box flex={{ base: "none", md: 3 }} ml='20px' overflow='auto'>
                        <ColumnsTable
                          columnsData={eclColumnsDataDebt}
                          tableData={debtTableData.slice((currentPageD - 1) * debtResultsPerPage, currentPageD * debtResultsPerPage)}
                          showThead={true} />
                        <Flex className="pagination-container" alignItems={'center'} justify={'flex-end'} gap={'20px'} my="25px" whiteSpace={'nowrap'}>
                          <Flex gap={'20px'} bg={'white'} p={'5px 15px'} borderRadius={'0.375rem'} className="ecl-border-ui">
                            <button style={{ display: 'flex', alignItems: 'center' }} onClick={handleFirstPageClickD} className={`button ${currentPageD === 1 ? 'disabled' : ''}`}><Icon as={MdKeyboardDoubleArrowLeft} width='20px' height='20px' color='inherit' /> </button><span className="vertical-divider-line">&#124;</span>

                            <button style={{ display: 'flex', alignItems: 'center' }} onClick={handlePreviousPageClickD} className={`button ${previousPageD == null ? 'disabled' : ''}`}> <Icon as={MdNavigateBefore} width='20px' height='20px' color='inherit' /> </button><span className="vertical-divider-line">&#124;</span>

                            <span><strong>{currentPageD} of {totalPageD} </strong></span><span className="vertical-divider-line">&#124;</span>

                            <button style={{ display: 'flex', alignItems: 'center' }} onClick={handleNextPageClickD} className={`button ${nextPageD === null ? 'disabled' : ''}`}> <Icon as={MdNavigateNext} width='20px' height='20px' color='inherit' />  </button><span className="vertical-divider-line">&#124;</span>

                            <button style={{ display: 'flex', alignItems: 'center' }} onClick={handleLastPageClickD} className={`button ${currentPageD === totalPageD ? 'disabled' : ''}`}> <Icon as={MdKeyboardDoubleArrowRight} width='20px' height='20px' color='inherit' /> </button>
                          </Flex>
                        </Flex>
                      </Box>
                    </Flex>
                  </Box>

                  {/* ---------------------------------------------------------------New -Debt Estimation - section --------------------------------------------------------------- */}

                  <Flex gap={'20px'} className="custom-scrollbar" align={'flex-start'}>
                    <Flex gap={'20px'} className="custom-scrollbar" direction={'column'} flex={'1'}>
                      <Box>
                        <ColumnsTable
                          columnsData={eclDebtAggregation}
                          tableData={debtAggregation}
                          showThead={true}
                        />
                      </Box>
                      <Box>
                        <ColumnsTable
                          columnsData={eclDebtFromCodat}
                          tableData={debtFromCodat}
                          showThead={true}
                        />
                      </Box>

                    </Flex>
                    <Box flex={'1'}>
                      <ColumnsTable
                        columnsData={eclDebtFromBank}
                        tableData={debtFromBank}
                        showThead={true}
                      />
                    </Box>
                  </Flex>
                  <Flex mt={'40px'} gap={'20px'} className="custom-scrollbar">
                    <Box flex={{ base: "none", md: 1 }} >
                      <Box pb={{ base: "130px", md: "20px", xl: "20px" }}>
                        <Text color='black' fontSize='18px' fontWeight='700'>Line & Price Estimate</Text>
                      </Box>
                      <Flex
                        mb='20px'
                        direction={{ base: "column", md: "row" }}
                        spacing={{ base: "20px", xl: "20px" }}
                      >
                        <Box flex={{ base: "none", md: 1 }}>
                          <ColumnsTable
                            columnsData={eclLineColumn}
                            tableData={lineTable}
                            showThead={true}
                            maxHeightTable="570px"
                          />
                        </Box>
                      </Flex>
                    </Box>
                    <Box>
                      <Box pb={{ base: "130px", md: "20px", xl: "20px" }}>
                        <Text color='black' fontSize='18px' fontWeight='700'>Risk Tier Calculation</Text>
                      </Box>
                      <SimpleGrid
                        mb='20px'
                        columns={{ sm: 1, md: 2, lg: 3, xl: 2, "2xl": 2 }}
                        spacing={{ base: "20px", xl: "20px" }}>
                        {kpiData?.risk?.map((kpi, index) => (
                          <MiniStatistics key={index} startContent={<IconBox w='56px' h='56px' bg={boxBg} icon={<Icon w='32px' h='32px' as={kpi.icon} color={brandColor} />} />}
                            name={kpi.name}
                            value={kpi.value}
                          />
                        ))}
                      </SimpleGrid>
                      <Flex
                        className="custom-scrollbar"
                        direction={{ base: "column", md: "row" }}
                        spacing={{ base: "20px", xl: "20px" }}>
                        <Box flex={{ base: "none", md: 1 }}>
                          <ColumnsTable
                            columnsData={eclRiskColumn}
                            tableData={riskTable}
                            showThead={true}
                          />
                        </Box>
                      </Flex>
                    </Box>
                  </Flex>
                  <Box mt={'40px'}>
                    <Box pb={{ base: "130px", md: "20px", xl: "20px" }}>
                      <Text color='black' fontSize='18px' fontWeight='700'>Decision</Text>
                    </Box>
                    <Flex direction={{ base: "column", md: "row" }} spacing={{ base: "20px", xl: "20px" }}>
                      <Box flex={{ base: "none", md: 1 }}>
                        <ColumnsTable columnsData={eclDecisionColumn} tableData={summaryTable?.slice(0, 12)} showThead={true} maxHeightTable="650px" />
                      </Box>
                      <Box flex={{ base: "none", md: 1 }} ml='20px' overflow='auto'>
                        <ColumnsTable columnsData={eclDecisionColumn} tableData={summaryTable?.slice(12, summaryTable.length)} showThead={true} maxHeightTable="720px" />
                      </Box>
                    </Flex>
                  </Box>

                  {/* ---------------------------------------------------------FRAUD CHECKS-------------------------------------------------- */}
                  <Flex direction="column" mt={'40px'} mb='60px'>
                    <Box pb={{ base: "130px", md: "20px", xl: "20px" }}>
                      <Text color='black' fontSize='18px' fontWeight='700'>Fraud Checks</Text>
                    </Box>
                    <Box flex='1'>
                      <ColumnsTable
                        customFontWeight='700'
                        customBgColor='rgba(0, 162, 173, 0.1)'
                        borderRadius={'20px 20px 0px 0px'}
                        titleData="DUPLICATION RULES"
                        columnsData={duplicationRulesColumns}
                        tableData={duplicationRules}
                        showThead={true}
                      />
                    </Box>
                    <Box flex='1'>
                      <ColumnsTable
                        customFontWeight='700'
                        customBgColor='rgba(0, 162, 173, 0.1)'
                        borderRadius={'0px 0px 20px 20px'}
                        titleData="VALUE & THRESHOLD RULES "
                        columnsData={valueThresholdRulesColumns}
                        tableData={thresholdRules}
                        showThead={true}
                      />
                    </Box>
                  </Flex>
                  {/* ---------------------------------------------------------FRAUD CHECKS-------------------------------------------------- */}

                </>}

      <CustomRevenuePopup calculation={calculation} customRevenueDetails={customRevenueDetails} processValue={processValue} setInputValueIndicator={setInputValueIndicator} setSelectedCustomRevenuePopupOptionsIndicator={setSelectedCustomRevenuePopupOptionsIndicator} setCustomRevenueDetails={setCustomRevenueDetails} customRevenueEditedValue={customRevenueEditedValue} minPercentage={minPercentage} maxPercentage={maxPercentage} setSelectedCustomRevenuePopupOptions={setSelectedCustomRevenuePopupOptions} inputValue={inputValue} setInputValue={setInputValue} updatedValue={updatedValue} setUpdatedValue={setUpdatedValue} setCustomRevenueEditedValue={setCustomRevenueEditedValue} onClose={handleCloseModal} isOpen={isRevenueModalOpen} customRevenuePopupOptions={customRevenuePopupOptions} selectedCustomRevenuePopupOptions={selectedCustomRevenuePopupOptions} handleCustomRevenuePopupOption={handleCustomRevenuePopupOption} />

      <CustomDebtPopup fileCommentArray1={fileCommentArray1} setFileCommentArray1={setFileCommentArray1} fileDataArray1={fileDataArray1} setFileDataArray1={setFileDataArray1}
        commentIsRequired={commentIsRequired} setCommentIsRequired={setCommentIsRequired} isEditing={isEditing} setIsEditing={setIsEditing}
        onSubmitFileComment={onSubmitFileComment} fileCommentLoader={fileCommentLoader} setFileCommentLoader={setFileCommentLoader}
        deleteCustomDebtProofs={deleteCustomDebtProofs} deleteParticularCustomDebtProof={deleteParticularCustomDebtProof} updateParticularCustomDebtProof={updateParticularCustomDebtProof} fileDataArray={fileDataArray} setFileDataArray={setFileDataArray} customDebtEditedValue={customDebtEditedValue} setCustomDebtEditedValue={setCustomDebtEditedValue} fileCommentArray={fileCommentArray} setFileCommentArray={setFileCommentArray} comments={comments} setComments={setComments} totalBasedUpdatedValue={totalBasedUpdatedValue} setTotalBasedUpdatedValue={setTotalBasedUpdatedValue} debtCustomInputValue={debtCustomInputValue} codatBasedInputValue={codatBasedInputValue} codatBasedUpdatedValue={codatBasedUpdatedValue} setCodatBasedUpdatedValue={setCodatBasedUpdatedValue} setBankBasedUpdatedValue={setBankBasedUpdatedValue} bankBasedUpdatedValue={bankBasedUpdatedValue} bankBasedPopupData={bankBasedPopupData} codatBasedPopupData={codatBasedPopupData} setBankBasedInputValue={setBankBasedInputValue} setCodatBasedInputValue={setCodatBasedInputValue} setDebtCustomInputValue={setDebtCustomInputValue} bankBasedInputValue={bankBasedInputValue} onClose={handleCloseModal} isOpen={isDebtModalOpen} />
      <ToastContainer />
    </Box>
  );
}